<template>
  <div class="apply-info-box form-box">
    <p class="tip">
      <span class="red">*</span>为了避免影响您的评审结果，请谨慎填写以下报名信息
    </p>
    <el-form :model="form" :rules="rules" ref="form" label-width="0px" class="demo-ruleForm">
      <div class="row">
        <div class="item">
          <el-form-item label prop="name">
            <label for="name">姓名</label>
            <div class="input">
              <el-input type="text" id="name" placeholder="请填写姓名" v-model="form.name" />
            </div>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label prop="sex">
            <label for="sex">性别</label>
            <div class="input">
              <el-select v-model="form.sex" placeholder="请选择性别">
                <el-option
                  v-for="item in sexOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label prop="brithDay">
            <label for="date">出生日期</label>
            <div class="input">
              <el-date-picker
                v-model="form.brithDay"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择出生日期"
              ></el-date-picker>
            </div>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label prop="email">
            <label for="phone">电子邮箱</label>
            <div class="input">
              <el-input type="text" id="email" placeholder="请填写电子邮箱" v-model="form.email" />
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label prop="sfycjzgbzkc">
            <label for="sex">是否有参加正规的编织课程</label>
            <div class="input">
              <el-radio v-model="form.sfycjzgbzkc" label="是">是</el-radio>
              <el-radio v-model="form.sfycjzgbzkc" label="否">否</el-radio>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row end">
        <div class="item">
          <el-form-item label prop="provincial">
            <label for>现居住地</label>
            <div class="input">
              <el-select v-model="form.provincial" @change="onChangeProvince" placeholder="请选择省">
                <el-option
                  v-for="item in provinceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>

        <div class="item">
          <el-form-item label prop="city">
            <label for>&nbsp;</label>
            <div class="input">
              <el-select v-model="form.city" @change="onChangeCity" placeholder="请选择市">
                <el-option
                  v-for="item in cityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label prop="district">
            <label for>&nbsp;</label>
            <div class="input">
              <el-select v-model="form.district" placeholder="请选择区">
                <el-option
                  v-for="item in districtOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label prop="nowAdress">
            <div class="input">
              <el-input type="text" id="nowAdress" placeholder="请填写详细地址" v-model="form.nowAdress" />
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="submit-btn btn" @click="onSubmit">下一步</div>
  </div>
</template>
<script>
import { getProvincesApi } from "@/api/common";
import { updateUserApi } from "@/api/user";
import FastClick from "fastclick";
export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        sex: "",
        brithDay: "",
        provincial: "",
        city: "",
        district: "",
        sfycjzgbzkc: "是",
        nowAdress: ""
      },
      pId: "0",
      sexOptions: [
        {
          value: "男",
          label: "男"
        },
        {
          value: "女",
          label: "女"
        }
      ],
      provinceOptions: [],
      cityOptions: [],
      districtOptions: [],
      rules: {
        name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        sex: {
          required: true,
          message: "请选择性别",
          trigger: "blur"
        },
        brithDay: [
          { required: true, message: "请填写出生日期", trigger: "blur" }
        ],
        email: [
          { required: true, message: "请填写电子邮箱", trigger: "blur" },
          {
            pattern: /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/,
            trigger: "blur",
            message: "请输入正确的电子邮箱"
          }
        ],
        nowAdress: {
          required: true,
          message: "请填写详细地址",
          trigger: "blur"
        },
        sfycjzgbzkc: [
          {
            required: true,
            message: "请选择是否参加过正规的编织课程",
            trigger: "blur"
          }
        ],
        provincial: { required: true, message: "请选择省", trigger: "blur" },
        city: { required: true, message: "请选择市", trigger: "blur" },
        district: { required: true, message: "请选择区", trigger: "blur" }
      }
    };
  },
  created() {
    this.form = JSON.parse(localStorage.getItem("info"));
    if (this.form.dataIntegrity == "0") {
      this.form.name = "";
    }
    this.getProvinces("provinceOptions", this.pId);
  },
  methods: {
    async getProvinces(key, pId) {
      let res = await getProvincesApi({ parentId: pId });
      if (res && res.code === 200) {
        this[key] = res.data.map(item => {
          item.label = item.name;
          item.value = item.id;
          return item;
        });
        if (key === "districtOptions" && !this.districtOptions.length) {
          this.rules.district.required = false;
        } else {
          this.rules.district.required = true;
        }
      }
    },
    onChangeProvince(e) {
      this.pId = e;
      this.form.city = "";
      this.form.district = "";
      this.cityOptions = [];
      this.districtOptions = [];
      this.getProvinces("cityOptions", e);
    },
    onChangeCity(e) {
      this.pId = e;
      this.getProvinces("districtOptions", e);
      this.districtOptions = [];
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.form.dataIntegrity = "1";
          let res = await updateUserApi(this.form);
          if (res && res.code == 200) {
            this.$emit("next", 3);
          } else {
            this.$message.error(res.msg || '系统错误');
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.apply-info-box {
}
</style>
