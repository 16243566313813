import request  from "@/service/index"

export function getProvincesApi(data){
  return request({
    url: "/sys-adress-code/listData",
    method: "post",
    data,
  });
}

export function recordPage(){
  return request({
    url: "/sys-operation-log/insert",
    method: "get",
  });
}