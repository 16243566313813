import Vue from 'vue'
import VueRouter from 'vue-router'
import Apply from '../views/apply/index.vue'
import {recordPage} from "@/api/common"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'appyly',
    component: Apply
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import( '../views/other/index.vue')
  },
  {
    path: '/publicity',
    name: 'publicity',
    component: () => import( '../views/other/index.vue')
  },
  {
    path: '/all',
    name: 'all',
    component: () => import( '../views/other/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  recordPage()
  next(); // 确保要调用 next()
})

export default router
