<template>
  <div class="step-list">
    <template v-for="(item) in list">
      <div class="step-item" :class="item.index-1 < step ? 'active' : ''" :key="item.num">
        <div class="num">{{item.num}}</div>
        <div class="text">{{item.text}}</div>
      </div>
      <img :key="item.num" src="@/assets/images/next1.png" alt />
    </template>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      list: [
        {
          num: 1,
          index:1,
          text: "参赛报名信息填写"
        },
        {
          num: 2,
          index:3,
          text: "上传评审作品"
        },
        {
          num: 3,
          index:4,
          text: "评审结果"
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.step-list {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &>*:last-child{
      display: none;
    }
  .step-item {
    display: flex;
    &.active {
      .num {
        background-color: #ee4036;
        color: #fff;
      }
      .text {
        color: #ee4036;
      }
    }
    .text{
      line-height: 30px;
      margin-right: 10px;
      font-size: 24px;
    }
    .num {
      border: 1px solid #ee4036;
      width: 30px;
      height: 30px;
      line-height: 30px;
      box-sizing: border-box;
      text-align: center;
      border-radius: 50%;
      color: #ee4036;
      margin-right: 5px;
    }
  }
  img{
    margin-right: 10px;
    flex:none;
  }
}
</style>