<template>
  <div class="cover">
    <div class="notice-dialog">
      <div class="title">参赛者需知及同意书</div>
      <div class="content">
        <div class="item">
          <p class="title">(1)参赛作品</p>
          <p>参赛作品必须完全原创, 曾经商业配对或设计的作品將不被考虑.</p>
        </div>
        <div class="item">
          <p class="title">(2)作品递交和展示责任</p>
          <p>主办方 “和麻纳卡株式会社“和” 和麻纳卡(广州) 贸易有限公司” (以下简称主办方) 会尽力小心处理参赛作品, 但所有参赛者必须注意以下情况 :</p>
          <p>a.运输过程中的损坏</p>
          <p>请仔细包装你的作品, 以免在运输过程中损坏; 如果作品在收到前有所损坏, 主办方將不会承担责任.</p>
          <p>b.在展览期间受到损坏</p>
          <p>入围决赛階段的作品將会在2024年10月18 -20日在上海世贸商城主办方产品展示会上展出; 在展示期间因为一些主办方无法控制原因而造成的损坏, 主办方概不负责.</p>
        </div>
        <div class="item">
          <p class="title">(3)版权</p>
          <p>a.参赛作品版权归参赛者所有.</p>
          <p>b.主办方可以在国内和国际发行的杂志, 书籍, 和任何印刷材料或视频内容中介绍获奖作品及其详细信息, 包括获奖者姓名.</p>
          <p>c.主办方不会对任何第三者侵犯版权行为负责.</p>
        </div>
        <div class="item">
          <p class="title">(4)个人资料处理</p>
          <p>照片及报名表恕不退还. 主办方会严格处理所有参赛者个人资料, 除下述因比赛需要措施外, 不会用于其他目的.</p>
          <p>a.竞赛评审结果通知及退还作品</p>
          <p>b.在公众投票页面披露和公布介绍作品及其参赛者资料.</p>
          <p>c.得奬者作品和个人资料会有需要作公开发布.</p>
        </div>
        <div class="item">
          <p class="title">(5)参赛者必须确认及同意以上比赛需知.</p>
        </div>
      </div>

      <div class="btn" @click="onClose">同意</div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    onClose() {
      this.$emit("change");
    }
  }
};
</script>
<style lang="scss" scoped>
.cover {
  position: fixed;
  z-index: 9999;
  background-color: rgba($color: #000000, $alpha: 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.notice-dialog {
  width: 800px;
  max-height: 85vh;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 40px;
  background-image: url(@/assets/images/bg3.png);
  background-color: #fff;
  &>.content{
    overflow: auto;
  }
  & > .title {
    color: #ee4036;
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
  }
  .item {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 10px;
    .title {
      font-weight: 600;
      color: #ee4036;
    }
  }
  .btn {
    width: 45%;
    text-align: center;
    margin: 10px auto 0;
    cursor: pointer;
    line-height: 60px;
    height: 60px;
    border-radius: 6px;
    color: #fff;
    background-color: #ee4036;
  }
}
</style>