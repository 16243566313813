<template>
  <div class="apply">
    <headerBox />
    <div class="content">
      <stepBox :step="step"></stepBox>
      <loginBox v-if="step == 1" @next="onNext"></loginBox>
      <noticeDialog v-if="noticeShow && step == 2" @change="noticeShow=false"></noticeDialog>
      <applyInfoBox v-if="step == 2" @next="onNext"></applyInfoBox>
      <uploadDataBox v-if="step == 3 || step == 5" @next="onNext" :disabled="step===5"></uploadDataBox>
      <resultBox v-if="step === 4" @preview="step=5"></resultBox>
    </div>
  </div>
</template>

<script>
import headerBox from "./components/headerBox.vue";
import loginBox from "./components/loginBox.vue";
import stepBox from "./components/stepBox.vue";
import applyInfoBox from "./components/applyInfoBox.vue";
import uploadDataBox from "./components/uploadDataBox.vue";
import resultBox from "./components/resultBox.vue";
import noticeDialog from "./components/noticeDialog.vue";
import { getCurUserApi } from "@/api/user.js";

export default {
  name: "ApplyView",
  components: {
    headerBox,
    loginBox,
    stepBox,
    applyInfoBox,
    uploadDataBox,
    resultBox,
    noticeDialog
  },
  computed: {
    isLogin() {
      return localStorage.getItem("token");
    }
  },
  data() {
    return {
      step: 1,
      noticeShow:true
    };
  },
  created() {},
  methods: {
    onNext(step) {
      this.step = step;
      window.scroll(0, 0);
    }
  }
};
</script>
<style lang="scss" scoped>
.apply {
  .content {
    position: relative;
    top: -100px;
    box-shadow: 0px 5px 10px #eee;
    margin: 0px auto;
    background-image: url(@/assets/images/bg.png);
    background-size: 100% auto;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    padding-top: 100px;
    border-radius: 30px;
    width: 1200px;
    padding-bottom: 10px;
  }
}
</style>
