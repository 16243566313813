<template>
  <div class="result-box">
      <h1 class="title">提交成功!</h1>
      <div class="circle">
        <img src="@/assets/images/success.png" alt="">
      </div>
      <p class="sub-title">您的作品已成功提交，请耐心等待审核···</p>
      <div class="form-box">
        <div class="submit-btn btn" @click="onPreview">预览作品</div>
      </div>
  </div>
</template>
<script>
export default {
  methods:{
    onPreview(){
      this.$emit('preview')
    }
  }
}
</script>
<style lang="scss" scoped>
.result-box{
  margin: 40px auto 80px;
}
.title{
  font-size: 30px;
  color: #EE4036;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.circle{
  width: 240px;
  margin: auto;
  height: 240px;
  background-color: #F9F4EE;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 30px;
}
.sub-title{
  text-align: center;
}
</style>