import request from "@/service/index";

export function getSmsApi(data) {
  return request({
    url: "/sys-user/getSms",
    method: "post",
    data,
  });
}

export function loginApi(data) {
  return request({
    url: "/sys-user/login",
    method: "post",
    data,
  });
}

export function getCurUserApi(data) {
  return request({
    url: "/sys-user/getCurUser",
    method: "post",
    data,
  });
}

export function getCodeImgApi() {
  return request({
    url: "/sys-user/captchaImage",
    method: "post",
  });
}

export function updateUserApi(data) {
  return request({
    url: "/sys-user/update",
    method: "post",
    data,
  });
}
