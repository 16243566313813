<template>
  <el-form
    :disabled="disabled"
    :model="form"
    :rules="rules"
    ref="formRef"
    label-width="0px"
    class="demo-ruleForm"
  >
    <el-form-item label prop="type">
      <div
        class="item"
        style="display: flex; align-items: center; line-height: 1"
      >
        <div for="sex" style="margin-right: 30px">作品类别</div>
        <div class="input">
          <el-radio v-model="form.type" label="成衣组">成衣组</el-radio>
          <el-radio v-model="form.type" label="配饰/小物组"
            >配饰/小物组</el-radio
          >
        </div>
      </div>
    </el-form-item>
    <div class="row">
      <div class="item">
        <el-form-item label prop="name">
          <label for="name"> <span class="red">*</span>作品名称 </label>
          <div class="input">
            <el-input type="text" id="name" placeholder v-model="form.name" />
          </div>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="item">
        <el-form-item label prop="syxc1">
          <label for="syxc1">
            <span class="red">*</span>使用线材（主要）1
          </label>
          <div class="input">
            <el-input type="text" id="syxc1" placeholder v-model="form.syxc1" />
          </div>
        </el-form-item>
      </div>
      <div class="item">
        <el-form-item label prop="syxcsyl1">
          <label for="syxcsyl1"> <span class="red">*</span>使用量/克 1 </label>
          <div class="input">
            <el-input
              type="text"
              id="syxcsyl1"
              placeholder
              v-model="form.syxcsyl1"
            />
          </div>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="item">
        <el-form-item>
          <label for="name">使用线材（主要）2</label>
          <div class="input">
            <el-input type="text" id="name" placeholder v-model="form.syxc2" />
          </div>
        </el-form-item>
      </div>
      <div class="item">
        <el-form-item>
          <label for="syxcsyl1">使用量/克 2</label>
          <div class="input">
            <el-input
              type="text"
              id="syxcsyl1"
              placeholder
              v-model="form.syxcsyl2"
            />
          </div>
        </el-form-item>
      </div>
    </div>
    <div class="line"></div>
    <div class="row">
      <div class="item">
        <el-form-item prop="syfc1">
          <label for="syfc1">
            <span class="red">*</span>使用副材（配件）1
          </label>
          <div class="input">
            <el-input type="text" id="syfc1" placeholder v-model="form.syfc1" />
          </div>
        </el-form-item>
      </div>
      <div class="item">
        <el-form-item prop="syfcsyl1">
          <label for="syfcsyl1"> <span class="red">*</span>使用量/克 1 </label>
          <div class="input">
            <el-input
              type="text"
              id="syfcsyl1"
              placeholder
              v-model="form.syfcsyl1"
            />
          </div>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="item">
        <el-form-item>
          <label for="syfc2">使用副材（配件）2</label>
          <div class="input">
            <el-input type="text" id="syfc2" placeholder v-model="form.syfc2" />
          </div>
        </el-form-item>
      </div>
      <div class="item">
        <el-form-item>
          <label for="syfcsyl2">使用量/克 2</label>
          <div class="input">
            <el-input
              type="text"
              id="syfcsyl2"
              placeholder
              v-model="form.syfcsyl2"
            />
          </div>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="item files">
        <el-form-item prop="files">
          <label for="name"> <span class="red">*</span>上传图片 </label>
          <div class="input">
            <el-upload
              action="http://123.207.57.81:9301/operation/file-info/upload"
              list-type="picture-card"
              :file-list="fileList"
              :on-remove="onRemove"
              :limit="3"
              :http-request="onUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="hint">最多上传3张，每张单个文件不超过10M</div>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="item">
        <el-form-item prop="description">
          <label for="description"> <span class="red">*</span>作品描述 </label>
          <div class="input">
            <el-input
              id="description"
              type="textarea"
              :rows="4"
              placeholder
              v-model="form.description"
            ></el-input>
          </div>
        </el-form-item>
      </div>
    </div>
    <div class="btn-group" v-if="!disabled">
      <div class="add btn" @click="onAdd">新增作品</div>
      <div class="del btn" @click="onDel">删除作品</div>
    </div>
    <div class="line"></div>
  </el-form>
</template>
<script>
import { uploadFileApi } from "@/api/works";

export default {
  props: {
    disabled: {
      default: false,
    },
    data: {
      default: () => {},
    },
  },
  data() {
    return {
      sort: 1,
      form: {
        type: "成衣组",
        name: "",
        syxc1: "",
        syxcsyl1: "",
        syxc2: "",
        syxcsyl2: "",
        syfc1: "",
        syfcsyl1: "",
        syfc2: "",
        syfcsyl2: "",
        files: [],
        description: "",
      },
      // 存储图片对应的id  删除的时候用
      fileMap: {},
      fileList: [],
      sexOptions: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
      ],
      rules: {
        type: { required: true, message: "请选择作品类型", trigger: "blur" },
        name: { required: true, message: "请填写作品名称", trigger: "blur" },
        syxc1: {
          required: true,
          message: "请填写使用线材主要（1）",
          trigger: "blur",
        },
        syxcsyl1: {
          required: true,
          message: "请填写使用线材主要（1）的使用量",
          trigger: "blur",
        },
        syfc1: {
          required: true,
          message: "请填写使用副材（配件）1",
          trigger: "blur",
        },
        syfcsyl1: {
          required: true,
          message: "请填写使用副材（配件）1的使用量",
          trigger: "blur",
        },
        files: { required: true, message: "请上传作品图片", trigger: "blur" },
        description: {
          required: true,
          message: "请填写作品描述",
          trigger: "blur",
        },
      },
    };
  },
  created() {
    if (this.disabled) {
      this.form = this.data;
      this.fileList = this.form.files.map((item) => {
        return {
          url: item,
          name: item,
        };
      });
    }
  },
  mounted() {
    // fastclick 避免上传图片效果异常
    let el = document.querySelector(".files .el-upload__input");
    el.classList.add("needsclick");

    let el1 = document.querySelector(".files .el-upload");
    el1.classList.add("needsclick");

    let el2 = document.querySelector(".files .el-icon-plus");
    el2.classList.add("needsclick");
  },
  methods: {
    onRemove(e) {
      this.form.files = this.form.files.filter((item) => {
        return this.fileMap[e.name] !== item;
      });
    },
    onAdd() {
      this.$emit("add");
    },
    onDel() {
      this.$emit("del");
    },
    async onUpload(e) {
      let data = JSON.parse(localStorage.getItem("info"));
      let id = data.id;
      const formData = new FormData();
      formData.append("userId", id);
      formData.append("file", e.file);
      formData.append("sort", this.sort);
      let res = await uploadFileApi(formData);
      if (res && res.code == 200) {
        this.form.files.push(res.msg);
        this.fileMap[e.file.name] = res.msg;
        this.sort += 1;
      } else {
        this.$message.error(res.msg || '系统错误');
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate(async (valid) => {
          let form = JSON.parse(JSON.stringify(this.form));
          if (valid && this.form.files && this.form.files.length) {
            form.files = JSON.stringify(this.form.files);
            resolve(form);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  width: 100%;
  height: 1px;
  background-color: #cda45c;
  margin: 20px 0 30px;
}
.btn-group {
  display: flex;
  justify-content: center;
  margin: 10px auto 50px;
  .btn {
    width: 200px;
    background-color: #ee4036;
    height: 60px;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    &.add {
      margin-right: 15px;
    }
    &.del {
      background-color: #cda45c;
    }
  }
}
</style>
