<template>
  <div class="login-box form-box">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="0px"
      class="demo-ruleForm"
    >
      <div class="row">
        <div class="item">
          <el-form-item label prop="phone">
            <label for="phone">手机号码</label>
            <div class="input">
              <el-input
                type="text"
                id="phone"
                placeholder="请填写手机号码"
                v-model="form.phone"
              />
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label prop="codeImage">
            <label for="code">图形验证码</label>
            <div class="code-inpout input">
              <el-input
                type="text"
                placeholder="请填写图形验证码"
                v-model="form.codeImage"
              />
              <div class="right">
                <img :src="codeImg" @click="getCodeImg" class="code-img" alt />
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <el-form-item label prop="code">
            <label for="code">短信验证码</label>
            <div class="code-inpout input">
              <el-input
                type="text"
                placeholder="请填写短信验证码"
                v-model="form.code"
              />
              <div class="right">
                <div class="btn" @click="onGetCode">{{ codeText }}</div>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <div class="submit-btn btn" @click="onSubmit">下一步</div>
  </div>
</template>
<script>
import { loginApi, getCodeImgApi, getSmsApi, getCurUserApi } from "@/api/user";
import { getWorksApi } from "@/api/works";
export default {
  data() {
    return {
      codeImg: "",
      codeText: "获取手机验证码",
      countDown: 60,
      form: {
        phone: "",
        code: "",
        uuidImage: "",
        codeImage: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        codeImage: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCodeImg();
  },
  methods: {
    async onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await loginApi(this.form);
          if (res && res.token) {
            localStorage.setItem("token", res.token);
            this.$message.success("登录成功");
            this.getCurUser();
          } else {
            this.$message.error(res.msg || '系统错误');
            this.getCodeImg();
          }
        }
      });
    },
    async onGetCode() {
      if (this.countDown !== 60) {
        return;
      }
      if (!this.form.phone) {
        this.$message.warning("请填写手机号码");
        return;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.form.phone)) {
        return this.$message.warning("请输入正确的手机号码");
      }
      let res = await getSmsApi({ phone: this.form.phone });
      if (res) {
        this.$message.success("发送成功");
        this.updateButtonText();
      } else {
        this.$message.error(res.msg || '系统错误');
      }
    },
    async getCodeImg() {
      let res = await getCodeImgApi();
      if (res && res.code) {
        this.form.uuidImage = res.data.uuid;
        this.codeImg = res.data.img;
      }
    },
    updateButtonText() {
      if (this.countDown > 0) {
        this.codeText = `${this.countDown}秒后重新获取`;
        this.countDown--;
        setTimeout(this.updateButtonText, 1000);
      } else {
        this.codeText = "获取验证码";
        this.countDown = 60;
      }
    },
    async getCurUser() {
      // 查询到数据则跳到 上传作品表单
      let res = await getCurUserApi({
        phone: this.form.phone,
        code: this.form.code,
      });
      localStorage.setItem("info", JSON.stringify(res.data));
      if (res.data.dataIntegrity == "0") {
        localStorage.setItem("noInfo", true);
        this.$emit("next", 2);
      } else {
        this.getWorksDetail(res.data.id);
      }
    },
    async getWorksDetail(id) {
      let res = await getWorksApi({ size: 1, current: 1, createdBy: id });
      console.log(res);
      if (res && res.code === 200 && res.data?.records?.length) {
        localStorage.setItem("records", JSON.stringify(res.data.records));
        this.$emit("next", 4);
      } else {
        this.$emit("next", 3);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login-box {
  width: 600px;
  margin: 60px auto;
}
</style>
