import axios from "axios";
import { Message } from "element-ui";

const service = axios.create({
  baseURL: "https://application.hamanaka.com.cn/operation",
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      // config.data = JSON.stringify(config.data);
    }
    config.headers['token'] = localStorage.getItem('token');
    return config;
  },
  (error) => {
    console.log("error", error);
    Promise.reject(error);
  }
);
service.interceptors.response.use((response) => {
  try{
    return response?.data;
  } catch(e) {
    return null
  }
});
export default service;
