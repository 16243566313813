<template>
  <div class="data-box form-box">
    <p class="tip">
      <span class="red">*</span>请仔细填写您的参赛申请，一经提交不可修改！
    </p>
    <template v-for="(item, index) in list">
      <formBox
        :disabled="disabled"
        :data="item"
        :key="item.id"
        @add="onAdd"
        @del="onDel(index)"
        :ref="'formRef' + index"
      ></formBox
    ></template>
    <template v-if="!disabled">
      <div class="submit-btn btn" @click="onSubmit">提交参赛申请</div>
      <p class="gray-tip">请仔细填写您的参赛申请，一经提交不可修改！</p>
    </template>
  </div>
</template>
<script>
import formBox from "./formBox.vue";
import { uploadWorksApi,getWorksApi } from "@/api/works";

export default {
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  components: { formBox },
  data() {
    return {
      list: [{}],
    };
  },
  created() {
    if (this.disabled) {
      this.list = JSON.parse(localStorage.getItem("records"))?.map((item) => {
        item.files = JSON.parse(item.files).map((item) => {
          return "https://application.hamanaka.com.cn" + item;
        });
        return item;
      });
      console.log(this.list);
    }
  },
  methods: {
    onAdd() {
      this.list.push({ id: new Date() });
    },
    onDel(index) {
      if (this.list.length === 1){
        return this.$message.warning("删除失败，最少保留一个作品！");
      }

      this.list.splice(index, 1);
    },
    async onSubmit() {
      let data = await Promise.all(
        this.list.map((item, index) =>
          this.$refs["formRef" + index][0].validate()
        )
      );

      if (!data || data.length !== this.list.length) return;
      let res = await uploadWorksApi({ data });
      if (res && res.code === 200) {
        this.getWorksDetail(JSON.parse(localStorage.getItem('info')).id);
        this.$emit("next", 4);
      }else{
        this.$message.error(res.msg || '系统错误');
      }
    },
    async getWorksDetail(id) {
      let res = await getWorksApi({ size: 1, current: 1, createdBy: id });
      if (res && res.code === 200 && res.data?.records?.length) {
        localStorage.setItem("records", JSON.stringify(res.data.records));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.data-box {
  .line {
    width: 100%;
    height: 1px;
    background-color: #cda45c;
    margin: 20px 0 30px;
  }
  .gray-tip {
    color: #aaa;
    text-align: center;
    margin-top: 15px;
  }
}
</style>
