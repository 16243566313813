<template>
  <div id="app">
    <router-view />
    <FooterBox></FooterBox>
  </div>
</template>
<script>
import FooterBox from "@/components/FooterBox";
export default {
  components: {
    FooterBox
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: unset;
}

.el-scrollbar {
  .el-scrollbar__bar{
    opacity: 1 !important;
  }
}
</style>
