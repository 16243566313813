import request from "@/service/index";

export function uploadFileApi(data) {
  return request({
    url: "/file-info/upload",
    method: "post",
    data,
  });
}


export function fileDownloadApi(data) {
  return request({
    url: "/file-info/down",
    method: "post",
    data,
  });
}

export function uploadWorksApi(data) {
  return request({
    url: "/bus-works/insert",
    method: "post",
    data,
  });
}

export function getWorksDetailApi(data) {
  return request({
    url: "/bus-works/getId",
    method: "post",
    data,
  });
}


export function getWorksApi(data) {
  return request({
    url: "/bus-works/page",
    method: "post",
    data,
  });
}
